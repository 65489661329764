<template>
  <div class="log-detail">
    <!-- title & button -->
    <div class="log-title">
      {{ parsedEventType }} ({{ log.event_type }})
    </div>

    <!-- log detail -->
    <div
      v-if="parsedEventType=='시작 단계'"
      class="log-detail__row"
    >
      <div class="log-detail__col">
        <p>idx: {{ claimIdx }}</p>
        <p>캠페인 이름: {{ data.campaign_idx }}</p>
        <p>사유: {{ data.reason }}</p>
        <p>시작일: {{ log.created_at }}</p>
      </div>
      <div class="log-detail__col">
        <p>접수자 회원 타입: {{ data.member_type }}</p>
        <p>
          <a
            :href="$store.getters.toMemberDetail(data.member_type, data.member_idx)"
            target='_blank'
          >
            접수자 idx: {{ data.member_idx }}
          </a>
        </p>
        <p>대상자 회원 타입: {{ data.target_type }}</p>
        <p>
          <a
            :href="$store.getters.toMemberDetail(data.target_type, data.target_idx)"
            target='_blank'
          >
            대상자 idx: {{ data.target_idx }}
          </a>
        </p>
      </div>
    </div>

    <div
      v-if="parsedEventType=='이의제기 단계'"
      class="log-detail__row"
    >
      <div class="log-detail__col">
        <p>이의 제기 idx: {{ objectionData.idx }}</p>
        <p>사유: {{ objectionData.reason }}</p>
        <p>이의제기일: {{ log.created_at }}</p>
      </div>
      <div class="log-detail__col">
        <p>이의제기 회원 타입: {{ objectionData.member_type }}</p>
        <p>이의제기 회원 idx: {{ objectionData.member_idx }}</p>
        <p>대상자 회원 타입: {{ objectionData.target_type }}</p>
        <p>대상자 idx: {{ objectionData.target_idx }}</p>
      </div>
    </div>

    <div
      v-if="parsedEventType=='추가정보요청 단계'"
      style="margin-bottom: 15px;"
    >
      <div
        v-for="request, i in requestList"
        :key="i"
        style="margin-bottom: 10px; border-bottom: 1px solid #d2d7da;"
      >
        <p>To: {{ request.member_type }} {{ request.member_idx }}</p>
        <div
          v-for="answer, j in request.answer_list"
          :key="j"
          class="request-detail__row"
        >
          <div class="request-detail__col">
            <p>요청사항 {{ j+1 }} : {{ answer.request }}</p>
            <p>응답: {{ answer.answer }}</p>
            <p>파일:
                <a v-if="answer.file_url && answer.is_submitted " :href="answer.file_url">파일 내려받기</a>
                <a v-else-if="answer.is_submitted">제출 안 함</a>
                <a v-else>요청중...</a>
            </p>
          </div>
          <div class="request-detail__col">
            <p>제출 상태: {{ answer.is_submitted ? '제출됨' : '아직 안 됨' }}</p>
            <p>요청일: {{ request.created_at }}</p>
            <p>마감일: {{ request.expire_date }}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="parsedEventType=='재접수 단계'">
      <div
        v-for="reprocess, i in reprocessingList"
        :key="i"
        style="margin-bottom: 10px;"
      >
        <p>재접수 사유: {{ reprocess.reason }}</p>
        <p>재접수 일시: {{ reprocess.created_at }}</p>
      </div>
    </div>

    <div v-if="parsedEventType=='조정 단계'">
      <div
        v-for="resolution, i in resolutionList"
        :key="i"
        style="margin-bottom: 10px; border-bottom: 1px solid #d2d7da;"
      >
        <p>To: {{ resolution.member_type }} {{ resolution.member_idx }}</p>
        <div class="resolution-detail__row">
          <div class="resolution-detail__col">
            <p>조정안: {{ resolution.content }}</p>
            <p>액션 수행: {{ resolution.is_completed ? '수행함' : '아직 안 함' }}</p>
            <p>상대방 확인: {{ resolution.is_confirmed ? '확인함' : '아직 안 함' }}</p>
          </div>
          <div class="resolution-detail__col">
            <p>조정안 생성일: {{ resolution.created_at }}</p>
            <p>마감일: {{ resolution.expire_date }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  props: {
    log: Object,
    claimIdx: Number,
    eventTypeMap: Object
  },
  setup(props, context) {
    const data = props.log.log_data
    const objectionData = props.log.objection_data
    const requestList = props.log.request_list
    const reprocessingList = props.log.reprocessing_list
    const resolutionList = props.log.resolution_list

    const parsedEventType = computed(() => {
      return props.eventTypeMap[props.log.event_type]
    })

    return {
      parsedEventType,
      data,
      objectionData,
      requestList,
      reprocessingList,
      resolutionList,
    }
  },
})

</script>

<style>
.log-detail {
  background: #293145;
  margin: 10px;
  padding: 20px;
  border-radius: 5px;
}

.log-title {
  margin-bottom: 15px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}

.log-detail__row{
  display: flex;
  justify-content: space-between;
}

.log-detail__col {
  width: 100%;
}

.request-detail__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.request-detail__row .request-detail__col {
  width: 100%;
}

.resolution-detail__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.resolution-detail__row .resolution-detail__col {
  width: 100%;
}
</style>
