<template>
  <div class="claim-container">
    <!-- modal UI(requestDetail, submitResolution) start -->
    <Modal
      :isRequestModal="isRequestModal"
      :isResolutionModal="isResolutionModal"
      :claimIdx="claimIdx"
      @closeModal="closeModal"
    />
    <!-- modal end -->

    <!-- claim detail log start -->
    <div class="top">
      <p>
        Claim 상세
      </p>
      <p>
        현재 단계: {{ currentState }}
      </p>
    </div>
    <div class="botton-box">
      <b-tooltip target="requestDetailButton" triggers="hover">
      이의제기, 재접수 단계에서 가능합니다
      </b-tooltip>
      <b-button
        id="requestDetailButton"
        @click="showRequestModal"
      >
        추가정보 요청
      </b-button>
      <b-tooltip target="submitResolutionButton" triggers="hover">
      이의제기, 추가정보요청 및 재접수 단계에서 가능합니다
      </b-tooltip>
      <b-button
        id="submitResolutionButton"
        style="margin-left:10px;"
        @click="showResolutionModal"
      >
        조정안 제출
      </b-button>
    </div>
    <div
      v-for="log, i in claimLogs"
      :key="i"
    >
      <ClaimLog
        :log="log"
        :claimIdx="claimIdx"
        :eventTypeMap="eventTypeMap"
      />
    </div>
    <div
      v-if="currentState == 'RESOLVED'"
      class="log-detail"
    >
      <p>분쟁종료</p>
    </div>
    <!-- claim detail log end -->
  </div>
</template>

<script>
import { defineComponent, onUnmounted, ref, computed } from '@vue/composition-api'
import { BButton, BTooltip } from 'bootstrap-vue'
import campaignStoreModule from './CampaignStoreModule'
import ClaimLog from '@/components/Campaign/Claim/ClaimLog.vue'
import Modal from '@/components/Campaign/Claim/Modal.vue'
import store from '@/store'

export default defineComponent({
  props: {
    idx: String,
  },
  components: {
    ClaimLog,
    Modal,
    BButton,
    BTooltip
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const claimLogs = ref([])
    const claimIdx = ref(0)
    const isRequestModal = ref(false)
    const isResolutionModal = ref(false)
    const eventTypeMap = {
      CREATED: '시작 단계',
      CHECKING: '대상자 확인 중',
      CONFIRMING: '상대방의 액션 검토중',
      OBJECTING: '이의제기 단계',
      REQUESTING: '추가정보요청 단계',
      REPROCESSING: '재접수 단계',
      RESOLVING: '조정 단계'
    }

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, campaignStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchClaimDetail', props.idx)
      .then(res => {
        claimLogs.value = res.data.data.log_data
        claimIdx.value = parseInt(props.idx, 10)
        // console.log(claimLogs.value)
      })

    const currentState = computed(() => {
      return eventTypeMap[claimLogs.value[0].log_data.step]
    })

    const requestDetailAvailable = computed(() => {
      return (currentState.value === 'OBJECTING') || (currentState.value === 'REPROCESSING')
    })

    const submitResolutionAvailable = computed(() => {
      return (
        (currentState.value === 'OBJECTING') || (currentState.value === 'REPROCESSING')
        || (currentState.value === 'REQUESTING')
      )
    })

    const showRequestModal = () => {
      if (requestDetailAvailable.value === false){
        alert('추가자료 가능한 상태가 아닙니다.')
        return;
      }
      isRequestModal.value = true
    }

    const showResolutionModal = () => {
      if (submitResolutionAvailable.value === false){
        alert('조정안 제출 가능한 상태가 아닙니다.')
        return;
      }
      isResolutionModal.value = true
    }

    const closeModal = () => {
      isRequestModal.value = false
      isResolutionModal.value = false
    }

    return {
      claimLogs,
      claimIdx,
      currentState,
      eventTypeMap,
      isRequestModal,
      isResolutionModal,
      showRequestModal,
      showResolutionModal,
      closeModal,
      requestDetailAvailable,
      submitResolutionAvailable,
    }
  },
})
</script>

<style>
.log-detail {
  background: #293145;
  margin: 10px;
  padding: 20px;
  border-radius: 5px;
}

.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.top p {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
}

.botton-box {
  display: flex;
  justify-content: right;
  margin-right: 10px;
}

</style>
