<template>
  <div
    v-if="isRequestModal || isResolutionModal"
    class="black-bg"
  >
    <div>
      <div
        v-if="isRequestModal"
        class="white-bg"
      >
        <p class="title">
          추가정보요청
        </p>
        <div class="request-modal__row">
          <div class="request-modal__col">
            <input
              :value="requestToApplicant[0]"
              @input="addRequest($event, 0, 'applicant')"
            >
            <b-button @click="pushInput(0, 'applicant')">
              접수자에게 요청사항 추가
            </b-button>
            <div
              v-for="request, i in requestToApplicant.slice(1, requestToApplicant.length)"
              :key="i"
            >
              <p>{{ requestToApplicant[i+1] }}
                <b-button
                  size="sm"
                  @click="requestToApplicant.splice(i+1, 1)"
                >x
                </b-button>
              </p>
            </div>
          </div>
          <div class="request-modal__col">
            <input
              :value="requestToTarget[0]"
              @input="addRequest($event, 0, 'target')"
            >
            <b-button @click="pushInput(0, 'target')">
              대상자에게 요청사항 추가
            </b-button>
            <div
              v-for="request, i in requestToTarget.slice(1, requestToTarget.length)"
              :key="i"
              class="request-modal__col"
            >
              <p>
                {{ requestToTarget[i+1] }}
                <b-button 
                  @click="requestToTarget.splice(i+1, 1)"
                  size="sm"
                >x
                </b-button>
              </p>
            </div>
          </div>
        </div>
        <div style="margin-top:10px;">
          <b-button @click="sendRequest">
            보내기
          </b-button>
          <b-button @click="closeModal">
            닫기
          </b-button>
        </div>
      </div>
    </div>
    <div
      v-if="isResolutionModal"
      class="white-bg"
    >
      <p>조정안 작성</p>
      <div class="resolution-content">
        <label style="font-size: 18px;">
          조정안 내용: <textarea
            v-model="resolutionContent"
            style="margin-left:10px;"
          />
        </label>
      </div>
      <div>
        <label>
          <input
            v-model="toApplicant"
            type="checkbox"
            :value="toApplicant"
          >접수자의 행동 필요
        </label>
        <label><input
          v-model="toTarget"
          type="checkbox"
          :value="toTarget"
        >대상자의 행동 필요</label>
      </div>
      <div class="button-box">
        <b-button @click="sendResolution">
          보내기
        </b-button>
        <b-button @click="closeModal">
          닫기
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent, ref, reactive } from '@vue/composition-api'
import { BButton } from 'bootstrap-vue'
import axios from '@axios'

export default defineComponent({
  props: {
    isRequestModal: Boolean,
    isResolutionModal: Boolean,
    claimIdx: Number,
  },
  components: {
    BButton,
  },
  setup(props, context) {
    const resolutionContent = ref('')
    const toApplicant = ref(false)
    const toTarget = ref(false)
    const requestToApplicant = ref([''])
    const requestToTarget = ref([''])

    const requestFormGroup = reactive({
      applicant: requestToApplicant,
      target: requestToTarget,
    })

    const addRequest = (event, idx, key) => {
      requestFormGroup[key].splice(idx, 1, event.target.value)
    }

    const pushInput = (idx, key) => {
      requestFormGroup[key].splice(idx, idx, '')
    }

    const closeModal = () => {
      context.emit('closeModal')
    }

    const sendResolution = () => {
      axios.post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/resolution/create-resolution/`, {
        claim_idx: props.claimIdx,
        content: resolutionContent.value,
        to_target: toTarget.value,
        to_applicant: toApplicant.value,
      }).then(() => {
        alert('조정안을 성공적으로 보냈습니다.')
      }).then(error => {
        alert('구현중')
        console.log(error.data)
      })
      context.emit('closeModal')
    }

    const sendRequest = () => {
      axios.post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/resolution/request-detail/`, {
        claim_idx: props.claimIdx,
        request_to_applicant: requestToApplicant.value.slice(1),
        request_to_target: requestToTarget.value.slice(1),
      }).then(() => {
        alert('요청 완료하였습니다.')
      }).catch(error => {
        console.log(error)
        alert(error.data)
      })
      context.emit('closeModal')
    }

    return {
      resolutionContent,
      toApplicant,
      toTarget,
      requestToApplicant,
      requestToTarget,
      addRequest,
      pushInput,
      sendResolution,
      closeModal,
      sendRequest,
    }
  },
})
</script>

<style>
.request-modal__row {
  display: flex;
  justify-content: space-between;
}

.request-modal__col {
  width: 100%;
}

.black-bg {
  width: 100%; height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed; padding: 20px;
}

.white-bg {
  width: 60%; background: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  color: darkslateblue;
}

.white-bg label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: darkslateblue;
}

.white-bg textarea {
  width: 400px;
  height: 150px;
}

.white-bg input {
  margin-right:10px;
}

.white-bg button {
  margin-right:10px;
}

</style>
